<template>
    <div>
        <section class="hero is-primary">
            <div class="hero-body">
                <p class="title">
                    Thank You!
                </p>
                <p class="subtitle">
                  Credits to the people that helped Clappy
                </p>
            </div>
        </section>
        <div class="container mt-3">
          <p class="has-text-centered">Thank you to <strong>Jrewt</strong>, <strong>RootofGaul</strong>, <strong>Smiley</strong>, <strong>Ghiscari</strong> and <strong>Lucks</strong> for creating Clappy</p>
          <p class="has-text-centered">Thank you to <strong>Savage Celluloid</strong>, <strong>Shin</strong>, <strong>Meller</strong>, and <strong>Bobbochu</strong> for helping kickstart Clappy.</p>
          <p class="has-text-centered">Thank you to <strong>Jan</strong>, <strong>Speev</strong>, <strong>Jenna</strong>, <strong>TimothyLH</strong> for helping Clappy get ready for his premiere.</p>
          <p class="has-text-centered">Thank you Discord & the Movies and Filmmaking Community</p>

          <div class="columns is-justify-content-center pt-6 mt-4 is-multiline">
            <div class="column is-4">
              <b-image src="https://cdn.discordapp.com/attachments/794726687136546836/930223307884752917/VIP.png"></b-image>
            </div>
          </div>

          <p class="has-text-centered">These members are helping further Clappy and the Movies and Filmmaking Discord. We love you and appreciate you. ❤️</p>

          <div class="columns is-justify-content-center is-multiline">
            <div
              class="column is-2"
              v-for="(user, i) in premiums"
              :key="i">
              <div class="card">
                <div class="card-image">
                  <b-image :src="`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`"></b-image>
                </div>
                <div class="card-content">
                  <ul><strong>{{ user.username }}#{{ user.discriminator }}</strong></ul>
                  <ul v-if="user.settings.lastPremium">Since <strong>{{ new Date(user.settings.lastPremium).toLocaleDateString() }}</strong></ul>
                </div>
              </div>
            </div>
          </div>

          <infinite-loading @infinite="loadMore" spinner="spiral" class="pb-4">
            <template slot="no-more">All VIPs loaded.</template>
            <div slot="no-results">No VIPs found.</div>
          </infinite-loading>
        </div>
    </div>
</template>

<script>
import { getPremiums } from '@/api/guild'
import InfiniteLoading from 'vue-infinite-loading'
import { getUser } from '@/api/users'

export default {
  components: {
    InfiniteLoading
  },
  data: () => ({
    page: 1,
    premiums: []
  }),
  methods: {
    async loadMore ($state) {
      await this.fetchPremiums()

      $state.loaded()
      if (this.page === 0) $state.complete()
    },
    async fetchPremiums () {
      const premiums = await getPremiums(this.page)
      this.premiums.push(...premiums)

      if (premiums.length < 10) this.page = 0 // all loading done
      else this.page++
    },
    async fetchUser () {
      await this.$store.dispatch('user/setUser', await getUser())
    }
  },
  computed: {
    computedUser () {
      return (this.$store.state.user.user && this.$store.state.user.user.id) ? this.$store.state.user.user : null
    }
  },
  beforeMount () {
    if (!this.computedUser) { this.fetchUser() }
  }
}
</script>
